import React, { useEffect, useRef, useState } from 'react';
import { A11y, Pagination, Thumbs } from 'swiper/modules';
import { Swiper, SwiperClass, SwiperSlide, SwiperRef } from 'swiper/react';
import { useInView } from 'react-intersection-observer';
import 'swiper/css';
import 'swiper/css/pagination';
import { RenderNode } from '@contentful/rich-text-react-renderer';
import { Block, BLOCKS, Inline } from '@contentful/rich-text-types';

import {
  IAppFeatureAnimationFields,
  IAppFeatureAnimationItem
} from '../../../types/contentful';
import { ScrollReveal } from '../ScrollReveal';
import CtaWidget from '../CtaWidget';
import { DEFAULT_LABEL, DEFAULT_PLACEHOLDER } from '../CtaWidget/shared';
import { AppFeatureAnimationItem } from './AppFeatureAnimationItem';
import RichText from '../RichText';

const disclaimerOverrideOptions: RenderNode = {
  [BLOCKS.PARAGRAPH]: (_node: Block | Inline, children) => {
    return <p>{children}</p>;
  }
};

export function AppFeatureAnimation({
  items,
  ctaWidget
}: Omit<IAppFeatureAnimationFields, 'contentfulLabel'>) {
  const labelsSwiper = useRef<SwiperRef>(null);
  const [selectedItemIndex, setSelectedItemIndex] = useState(0);
  const [itemsState, setItemsState] = useState<Record<string, boolean>>(
    items.reduce((acc, item) => ({ ...acc, [item.fields.label]: false }), {})
  );
  const [inViewRef, inView] = useInView({ threshold: 0.25 });

  useEffect(() => {
    playItem(items[selectedItemIndex], inView);
  }, [inView, selectedItemIndex]);

  useEffect(() => {
    if (labelsSwiper.current) {
      labelsSwiper.current.swiper.update();
    }
  }, [selectedItemIndex]);

  const playItem = (item: IAppFeatureAnimationItem, play: boolean) => {
    setItemsState((prev) => ({
      ...prev,
      [item.fields?.label]: play
    }));
  };

  const handleSlideChange = (swiper: SwiperClass) => {
    const newIndex = swiper.activeIndex;
    setSelectedItemIndex(newIndex);
    playItem(items[newIndex], true);
    if (labelsSwiper.current) {
      labelsSwiper.current.swiper.slideTo(newIndex);
    }
  };

  const handleLabelClick = (index: number) => {
    setSelectedItemIndex(index);
    playItem(items[index], true);
    if (labelsSwiper.current) {
      labelsSwiper.current.swiper.slideTo(index);
    }
  };

  return (
    <ScrollReveal className="block-container">
      <div
        className="flex w-full flex-col-reverse justify-center desktop:flex-row"
        ref={inViewRef}
      >
        <div
          className="mx-3 tablet:mx-12 desktop:ml-auto desktop:w-6/12"
          data-testid="app-feature-animation-medias"
        >
          <Swiper
            className="app-feature-animation-medias tablet:h-[70vh]"
            direction="horizontal"
            modules={[A11y, Pagination, Thumbs]}
            slidesPerView={1}
            speed={700}
            thumbs={{ swiper: labelsSwiper.current?.swiper }}
            pagination={{
              el: '.medias-pagination',
              clickable: true
            }}
            onSwiper={(swiper: SwiperClass) => {
              playItem(items[0], true);
              swiper.on('slideChange', () => handleSlideChange(swiper));
            }}
            breakpoints={{
              1080: {
                spaceBetween: 500,
                direction: 'vertical',
                allowTouchMove: false,
                simulateTouch: false,
                pagination: false
              }
            }}
          >
            {items.map((item) => (
              <SwiperSlide
                key={item.fields?.label}
                className="!flex flex-col items-center justify-center bg-white"
                data-testid="app-feature-animation-media"
              >
                <div className="max-h-[calc(100%-32px)]">
                  <AppFeatureAnimationItem
                    media={item.fields.media}
                    play={inView && itemsState[item.fields.label]}
                    onEnded={() => playItem(item, false)}
                  />
                </div>
                {item.fields.disclaimer && (
                  <div className="mt-4 text-center text-xs leading-3">
                    <RichText
                      data={item.fields.disclaimer}
                      overrideOptions={disclaimerOverrideOptions}
                    />
                  </div>
                )}
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="medias-pagination mt-4 flex w-fit justify-center gap-4 py-2 desktop:hidden" />
          {ctaWidget && (
            <CtaWidget
              id="app-feature-animation-cta"
              module="App Feature Animation"
              height="fit-content"
              width="100%"
              className="app-feature-animation-cta mt-8 desktop:!hidden"
              errorPosition="bottom"
              label={DEFAULT_LABEL}
              placeholder={DEFAULT_PLACEHOLDER}
              {...ctaWidget?.fields}
              sysId={ctaWidget?.sys?.contentType?.sys?.id}
            />
          )}
        </div>
        <div
          className="m-auto mb-8 h-fit w-full overflow-hidden tablet:mb-8 desktop:mb-auto desktop:ml-0 desktop:mr-auto desktop:w-auto"
          data-testid="app-feature-animation-labels"
        >
          <Swiper
            ref={labelsSwiper}
            className="app-feature-animation-labels"
            slidesPerView="auto"
            direction="horizontal"
            modules={[A11y]}
            centeredSlides={true}
            spaceBetween={24}
            onBreakpoint={() => {
              if (screen.availWidth < 1080 && labelsSwiper.current) {
                labelsSwiper.current.swiper.params.centeredSlides = true;
                labelsSwiper.current.swiper.params.allowTouchMove = true;
                labelsSwiper.current.swiper.params.spaceBetween = 24;
                return labelsSwiper.current?.swiper.changeDirection(
                  'horizontal',
                  true
                );
              }
              return null;
            }}
            breakpoints={{
              720: {
                spaceBetween: 24
              },
              1080: {
                spaceBetween: 24,
                direction: 'vertical',
                allowTouchMove: false,
                centeredSlides: false
              }
            }}
          >
            {items.map((item, index) => (
              <SwiperSlide
                key={item.fields?.label}
                className={`!w-fit ${
                  selectedItemIndex === index ? 'text-teal' : 'text-[#B4B6B9]'
                }`}
                data-testid="app-feature-animation-label"
                onClick={() => handleLabelClick(index)}
              >
                <div className="relative w-fit cursor-pointer text-[28px] font-medium leading-8 transition-colors duration-200 ease-in-out after:absolute after:-bottom-1 after:left-0 after:h-1 after:w-0 after:bg-teal after:transition-all after:duration-200 tablet:text-[42px] tablet:leading-12 desktop:py-1 desktop:hover:text-teal desktop:hover:after:w-full">
                  {item.fields?.label}
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          {ctaWidget && (
            <CtaWidget
              id="app-feature-animation-cta"
              module="App Feature Animation"
              height="fit-content"
              className="app-feature-animation-cta mt-8 !hidden desktop:!flex"
              errorPosition="bottom"
              label={DEFAULT_LABEL}
              placeholder={DEFAULT_PLACEHOLDER}
              {...ctaWidget?.fields}
              sysId={ctaWidget?.sys?.contentType?.sys?.id}
            />
          )}
        </div>
      </div>
    </ScrollReveal>
  );
}
